<template>
  <div class="col-md-12">
    <div class="card card-container">
      <form @submit.prevent="BrandCreate">
        <div class="mb-3">
          <label for="name" class="form-label">Name</label>
          <input type="text" class="form-control" id="name" v-model="name" required="required" ref="name">
        </div>
        <button type="submit" class="btn btn-primary">create brand</button>
      </form>

    <div v-if="message" class="alert" :class="successful ? 'alert-success' : 'alert-danger'">
        {{ message }}
      </div>
    </div>

  </div>
</template>

<script>
import BrandService from "../services/brand.service";

export default {
  name: "brand create",
  methods: {
    BrandCreate() {
      this.formData = {
        'name': this.name,
      };

      BrandService.brandCreate(this.formData).then(
          (response) => {
            this.message = response.data.message;
            this.successful = true;
            this.loading = false;
            this.formData = {};
            this.name = '';
          },
          (error) => {
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            this.successful = false;
            this.loading = false;
          }
        );
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  data() {
    return {
      successful: false,
      loading: false,
      message: "",
      formData: {},
      name: '',
    };
  },
  mounted() {
    if (this.loggedIn) {
      // this.$router.push("/profile");
    }
  },
};

</script>

<style scoped>
</style>

